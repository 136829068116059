<template>
  <div>
    <h1 class="mr-sm-4 header-tablepage">System</h1>
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <div class="row">
        <div class="col-sm-6 col-12">
          <InputText
            textFloat="Invoice No. for Customer Use Coupon"
            placeholder="Name"
            type="text"
            name="name"
            isRequired
          />
        </div>
        <div class="break-ele"></div>
        <div class="col-12 col-sm-6">
          <InputText
            textFloat="Customer User Product (Article No.)"
            placeholder="Name"
            type="text"
            name="name"
            isRequired
          />
        </div>
        <div class="col-12 col-sm-6">
          <InputText
            textFloat="Customer Use Coupon Sale (Sale ID.)"
            placeholder="Name"
            type="text"
            name="name"
            isRequired
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.break-ele {
  flex-basis: 100%;
  height: 0;
}
</style>
